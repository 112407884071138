<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
      <v-spacer></v-spacer>
      <v-icon @click="$emit('update:dialog', false)">mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="selectedAdmin.name"
              :label="$t('admin.name')"
              @input="$v.selectedAdmin.name.$touch()"
              @blur="$v.selectedAdmin.name.$touch()"
              :error-messages="nameErrors"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              autocomplete="username"
              v-model="selectedAdmin.email"
              :label="$t('admin.email')"
              @input="$v.selectedAdmin.email.$touch()"
              @blur="$v.selectedAdmin.email.$touch()"
              :error-messages="emailErrors"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="!edited">
            <v-text-field
              autocomplete="new-password"
              type="password"
              v-model="selectedAdmin.password"
              :label="$t('admin.password')"
              @input="$v.selectedAdmin.password.$touch()"
              @blur="$v.selectedAdmin.password.$touch()"
              :error-messages="passwordErrors"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
          <v-col align="center" cols="12">
            <span>{{ $t("admin.permissions") }}</span>
          </v-col>
          <v-row v-for="(item, index) in permissionList" :key="index">
            <v-col class="module_name" align="center" cols="4">
              <span>{{ item.module_key }}</span>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="permissions"
                :label="$t('admin.read')"
                color="#1B5E20"
                :value="item.module_val[0]"
                hide-details="auto"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-if="item.module_val[1]"
                v-model="permissions"
                :label="$t('admin.write')"
                color="#1B5E20"
                :value="item.module_val[1]"
                hide-details="auto"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col class="module_name" align="center" cols="4">
            <span>{{ $t("admin.notification") }}</span>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="permissions"
              :label="$t('admin.write')"
              color="#1B5E20"
              :value="manageNotification"
              hide-details="auto"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="module_name" align="center" cols="4">
            <span>{{ $t("admin.janus") }}</span>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="permissions"
              :label="$t('admin.read')"
              color="#1B5E20"
              :value="viewJanus"
              hide-details="auto"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" dark dense @click="$emit('update:dialog', false)">
        {{ $t("admin.cancel") }}
      </v-btn>
      <v-btn color="primary" @click="save" :loading="saving" :disabled="saving">
        {{ $t("admin.save") }}
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  required,
  email,
  requiredIf,
  minLength,
} from "vuelidate/lib/validators";
import {
  VIEW_USER,
  MANAGE_USER,
  VIEW_ADMIN,
  MANAGE_ADMIN,
  VIEW_LEAD,
  MANAGE_LEAD,
  MANAGE_POSTING,
  VIEW_POSTING,
  MANAGE_NOTIFICATION,
  VIEW_FINANCIAL_DASHBOARD,
  MANAGE_FINANCIAL_DASHBOARD,
  VIEW_JANUS_DASHBOARD,
  VIEW_NEWS,
  MANAGE_NEWS,
  // VIEW_NOTIFICATION
} from "@/constants/permission";
import { isEmpty, cloneDeep } from "lodash";
import { showSnackbar } from "../../eventbus/action.js";
export default {
  props: {
    editedAdmin: {
      required: false,
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      manageNotification: MANAGE_NOTIFICATION,
      viewJanus: VIEW_JANUS_DASHBOARD,
      permissions: this.editedAdmin.permission
        ? this.editedAdmin.permission.map((val) => val.name)
        : [],
      permissionList: [
        {
          module_key: this.$t("admin.admins"),
          module_val: [VIEW_ADMIN, MANAGE_ADMIN],
        },
        {
          module_key: this.$t("admin.members"),
          module_val: [VIEW_USER, MANAGE_USER],
        },
        {
          module_key: this.$t("admin.leads"),
          module_val: [VIEW_LEAD, MANAGE_LEAD],
        },
        {
          module_key: this.$t("admin.posting"),
          module_val: [VIEW_POSTING, MANAGE_POSTING],
        },
        {
          module_key: this.$t("admin.financialDashboard"),
          module_val: [VIEW_FINANCIAL_DASHBOARD, MANAGE_FINANCIAL_DASHBOARD],
        },
        {
          module_key: this.$t("admin.news"),
          module_val: [VIEW_NEWS, MANAGE_NEWS],
        },
      ],
      saving: false,
      selectedAdmin: {},
      errors: {
        name: null,
        email: null,
        password: null,
      },
    };
  },
  validations: {
    selectedAdmin: {
      name: { required },
      email: { required, email },
      password: {
        requiredIf: requiredIf(function () {
          return !this.edited;
        }),
        minLength: minLength(8),
      },
    },
  },
  computed: {
    edited() {
      return !isEmpty(this.editedAdmin);
    },
    formTitle() {
      return this.edited
        ? this.$t("admin.editAdmin")
        : this.$t("admin.addAdmin");
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.selectedAdmin.name.$dirty) return errors;
      !this.$v.selectedAdmin.name.required &&
        errors.push(this.$t("admin.validations.nameIsRequired"));

      if (this.errors && this.errors.name) return this.errors.name;

      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.selectedAdmin.email.$dirty) return errors;
      !this.$v.selectedAdmin.email.email &&
        errors.push(this.$t("admin.validations.emailIsInvalid"));
      !this.$v.selectedAdmin.email.required &&
        errors.push(this.$t("admin.validations.emailIsRequired"));

      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.selectedAdmin.password.$dirty) return errors;
      !this.$v.selectedAdmin.password.requiredIf &&
        errors.push(this.$t("admin.validations.passwordIsRequired"));

      !this.$v.selectedAdmin.password.minLength &&
        errors.push(this.$t("admin.validations.passwordMinLength"));

      return errors;
    },
  },
  mounted() {
    if (this.edited) {
      this.selectedAdmin = cloneDeep(this.editedAdmin);
    }
  },
  methods: {
    ...mapActions({
      createAdmin: "admins/createAdmin",
      updateAdmin: "admins/updateAdmin",
    }),

    async save() {
      this.selectedAdmin.permissions = [...this.permissions];
      this.$v.selectedAdmin.$touch();
      if (!this.$v.selectedAdmin.$invalid) {
        try {
          this.saving = true;
          if (this.edited) {
            await this.updateAdmin({
              payload: this.selectedAdmin,
              adminId: this.editedAdmin.id,
            });
          } else {
            await this.createAdmin({ ...this.selectedAdmin });
          }
          this.$emit("update:dialog", false);
          this.$emit("refresh");
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showSnackbar(errorMessage);
          this.errors.name = message.name;
          this.errors.email = message.email;
          this.errors.password = message.password;
        } finally {
          this.saving = false;
        }
      }
    },
  },
};
</script>
