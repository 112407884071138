<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <change-password
        v-if="changePasswordDialog"
        userType="admin"
        :selectedUser="editedAdmin"
        :dialogStatus="changePasswordDialog"
        @close="changePasswordDialog = false"
        @refresh="initialize"
      ></change-password>
      <v-col cols="12">
        <v-dialog v-if="dialog" v-model="dialog" persistent max-width="500px">
          <CreateAndEditAdmin
            :dialog.sync="dialog"
            :edited-admin="editedAdmin"
            @refresh="getAdmins"
          />
        </v-dialog>
        <confirmation-dialog
          @close="dialogDelete = false"
          @confirm="deleteItemConfirm"
          :dialog="dialogDelete"
          :dialogHeadline="$t('admin.deleteHeadline')"
        ></confirmation-dialog>
        <div>
          <v-data-table
            :loading="loading"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :headers="headers"
            :items="admins"
            :search="search"
            @page-count="pageCount = $event"
            class="elevation-0 pb-4 pt-4"
          >
            <template v-slot:top class="px-3">
              <v-toolbar flat class="mb-4 px-3">
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('admin.search')"
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="manageAdmin"
                  color="primary"
                  dark
                  large
                  dense
                  elevation="4"
                  @click="
                    dialog = true;
                    editedAdmin = {};
                  "
                >
                  {{ $t("admin.addAdmin") }}
                </v-btn>
              </v-toolbar>
            </template>
            <template v-if="manageAdmin" v-slot:item.actions="{ item }">
              <v-btn
                class="mr-3 green-color"
                color="green"
                :ripple="false"
                icon
              >
                <v-icon
                  medium
                  @click="
                    dialog = true;
                    editedAdmin = item;
                  "
                  >mdi-pencil</v-icon
                >
              </v-btn>
              <v-btn color="error" class="mr-3 red-color" :ripple="false" icon>
                <v-icon
                  medium
                  @click="
                    editedAdmin = item;
                    dialogDelete = true;
                  "
                >
                  mdi-delete</v-icon
                > </v-btn
              >&nbsp;
              <v-btn
                class="green-color"
                color="green"
                icon
                @click="
                  editedAdmin = item;
                  changePasswordDialog = true;
                "
                ><v-icon>mdi-account-key</v-icon></v-btn
              >
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MANAGE_ADMIN } from "@/constants/permission";
import CreateAndEditAdmin from "../components/admins/CreateAndEditAdmin";
import { showSnackbar } from "../eventbus/action.js";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import ChangePassword from "../components/common/ChangePassword";
export default {
  components: { CreateAndEditAdmin, ConfirmationDialog, ChangePassword },
  data() {
    return {
      changePasswordDialog: false,
      manageAdmin: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      dialog: false,
      dialogDelete: false,
      loading: false,
      search: "",
      headers: [
        {
          text: this.$t("admin.name"),
          align: "start",
          value: "name",
          width: "25%",
        },
        {
          text: this.$t("admin.email"),
          value: "email",
          width: "30%",
        },
        {
          text: this.$t("admin.password"),
          value: "p_token",
          width: "25%",
        },
        {
          text: this.$t("admin.actions"),
          value: "actions",
          width: "20%",
          sortable: false,
        },
      ],
      editedAdmin: {},
    };
  },

  computed: {
    ...mapGetters({
      admins: "admins/getAdmins",
      user: "user/getUser",
      meta: "admins/getMeta",
    }),
  },
  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      getAdmins: "admins/getAdmins",
      deleteAdmin: "admins/deleteAdmin",
    }),
    async initialize() {
      const userPermission = this.user.permission.map((val) => val.name);
      this.manageAdmin = userPermission.includes(MANAGE_ADMIN);
      this.loading = true;
      try {
        await this.getAdmins();
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    async deleteItemConfirm() {
      this.$store.commit("setLoader", true);
      try {
        await this.deleteAdmin({
          adminId: this.editedAdmin.id,
        });
        this.getAdmins();
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
      this.dialogDelete = false;
    },
  },
};
</script>
